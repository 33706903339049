import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./form.module.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  const form = useRef();

  //sendEmail
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_0o96ze3", "template_ln7ovqe", form.current, "hQeTn2g0vq1VlDOxG").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  //show Toast success
  const showToastMessage = () => {
    toast.success('Votre mail a été envoyé avec succès !', {
        position: toast.POSITION.TOP_RIGHT
    });
  };

  //reset Form
  const resetForm = () => form.current.reset();

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="group">
          <label for="society"> Votre société </label> <br/>
          <input type="text"  name="name"/>
      </div>
      <div className="group">
          <label for="message"> Message </label> <br/>
          <textarea name="message" />
      </div>
      <div className="group">
          <label for="email"> Email </label> <br/>
          <input type="email" name="email" />
      </div>
      <input type="submit" value="Envoyer" onClick={showToastMessage} className="btn"/>
      <input type="button" value="Réinitialiser" onClick={resetForm} className="btn"/>
      <ToastContainer />
    </form>
  );
};

export default App;